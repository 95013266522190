.ant-table-row-expand-icon-cell.empty,
.ant-table-expand-icon-col {
  width: 0;
}

.page-information {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 10px;
}

.table {
  &__title {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    display: block;
    margin-bottom: 4px;

    &_type {
      &_empty {
        color: #cccccc;
      }
    }
  }

  &__url {
    display: flex;
    padding-right: 20px;
    word-break: break-all;
    line-height: 1;
    align-items: center;

    a {
      font-size: 0.9em;
      color: @text-color;
    }

    & + &-redirect-container {
      margin-top: 5px;
    }

    &-redirect-container {
      display: flex;
      flex-direction: column;

      & > & {
        margin-left: 30px;
      }

      &__item {
        display: flex;
        align-items: center;
        margin-top: 4px;

        &:first-child {
          margin-top: 0;
        }
      }

      &__icon-redirect {
        margin-left: 4px;
        margin-right: 5px;
        transform: scaleX(-1);
      }

      &__icon-redirect,
      &__icon-redirect-to {
        color: #cccccc;
        font-size: 0.8em;
        margin-right: 5px;
      }

      &__icon-redirect-to {
        width: 14px !important;
      }
    }

    &__protocol {
      font-size: 0.9em;
      margin-right: 5px;

      &_type_is-secure {
        color: @green-base;
      }
    }

    &__icon-external {
      font-size: 1.2em;
      font-weight: bold;
      padding: 1px;
      opacity: 0.5;
    }
  }

  &__description {
    font-size: 0.9em;
    max-width: 536px;
    margin-bottom: 0;

    &_type {
      &_empty {
        color: #cccccc;
      }
    }
  }
}

.input-with-search {
  position: relative;
  display: flex;
  align-items: center;

  .ant-input {
    padding-left: 20px;
  }

  .anticon {
    position: absolute;
    color: #bfbfbf;
  }
}
