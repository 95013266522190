@import "libs";
@import "variable";
@import "components";
@import "pages";

.ant-layout-sider {
  .ant-menu {
    font-weight: bold;
  }
}

.header {
  &__logo {
    width: 150px;
    height: 100%;
    display: inline-block;
    margin-right: 50px;

    &__img {
      width: auto;
    }
  }

  &__projects {
    cursor: pointer;

    &__label {
      color: rgb(162, 184, 197);
    }

    &__name {
      color: @white;
    }
  }

  &__user-info {
    &__name {
      color: @white;
    }
  }
}

.text-center {
  text-align: center;
}

.app-information {
  position: fixed;
  bottom: 0;
  right: 0;

  .ant-card-head {
    padding: 0 !important;
    min-height: 12px;

    &-title {
      background-color: #91d5ff;
      padding: 2px !important;
      font-size: 9px;
    }
  }
}

.app-information .ant-card-body {
  padding: 0;
}

pre {
  font-size: 12px;
}

#root {
  height: 100%;
  background: #f0f2f5;
}

.project-sidebar {
  background: #00405a;

  .ant-menu-inline .ant-menu-selected::after,
  .ant-menu-inline .ant-menu-item-selected::after {
    border-color: #caced0;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: none;
  }

  .ant-menu {
    background: #00405a !important;

    span,
    a,
    i,
    .ant-menu-submenu-title {
      color: #caced0 !important;
    }

    .ant-menu-item-selected {
      span,
      a,
      i,
      .ant-menu-submenu-title {
        color: #fff !important;
      }
    }

    span:hover,
    a:hover,
    i,
    .ant-menu-submenu-title:hover {
      color: #fff !important;
    }
  }
}

.project-list-card {
  &__remove-report {
    display: none !important;
  }

  &__item {
    .ant-popover {
      width: 76%;
    }
  }

  &__item:hover &__remove-report {
    display: block !important;
  }
}

.project-list {
  position: static;
}

.project-list__delete {
  opacity: 0;
  .ant-popover {
    width: 305px;
  }
}

.ant-card:hover .project-list__delete,
.ant-list:hover .project-list__delete {
  opacity: 1;
}

.content-with-sidebar {
  margin: 24px 24px;
  position: relative;
  height: calc(100% - 24px - 24px - 72px - 54px);
}

.ant-table-row {
  .table__url {
    .show-modal-about-page {
      color: transparent;
    }
  }

  &:hover {
    .table__url {
      .show-modal-about-page {
        color: inherit;
      }
    }
  }
}
