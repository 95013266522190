.tree-page-urls {
  .anticon.anticon-folder-open, .anticon.anticon-folder {
    color: @primary-color;
  }

  .ant-tree-node-selected {
    .anticon.anticon-folder-open, .anticon.anticon-folder {
      color: #fff;
    }
  }

  .ant-tree-title button {
    color: transparent;
  }
  .ant-tree-node-content-wrapper:hover {
    .ant-tree-title button {
      color: inherit;
    }
  }
}
