@border-radius: 4px;

.ant-btn:not(:is(.ant-btn-circle)) {
  border-radius: @border-radius;
}

.ant-btn-group,
.ant-input-group {
  & .ant-btn:last-child {
    border-top-right-radius: @border-radius;
    border-bottom-right-radius: @border-radius;
  }
  & .ant-btn:first-child {
    border-top-left-radius: @border-radius;
    border-bottom-left-radius: @border-radius;
  }

  & .ant-btn {
    border-radius: 0;
  }
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: #00b8ff;
}

.floating-button {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
}
