.report {

  &-pending {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__panel {
      width: 100%;
      background-color: #fff;
      padding: 25px;
    }

    &__item {
      display: flex;
      flex-direction: column;

      &-information {
        display: flex;
      }

      &-name {
        display: block;
        white-space: nowrap;
        margin-right: 25px;
      }

      &-value {
        margin-left: auto;
      }

      & .ant-progress-text:empty {
        display: none;
      }

      & .ant-progress-show-info .ant-progress-outer {
        margin-right: 0;
        padding-right: 0;
      }
    }
  }
}
